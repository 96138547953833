<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'quotes'}">Quotes</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Request Quote</a></li>
    </ol>
    <h1 class="page-header">Request Quote</h1>
    <panel noButton="true" title="Request Quote" variant="panel-default">
      <div v-html="$error.handle(error)"/>
      <form class="row"
          v-if="step === 1"
          @submit.prevent="validateForm"
      >
        <div class="form-group col-md-4">
          <label>Procuring Entity</label>
          <v-select
              v-model="quote.counterparty"
              v-validate="'required'"
              :class="{'is-invalid': errors.has('counterparty') }"
              :options="counter_parties"
              :reduce="c => c.id"
              label="name"
              name="counterparty"
              placeholder="Select Counter Party"
          />
          <div :class="{'invalid-feedback': errors.has('counterparty')}">
            {{ errors.first('counterparty') }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label>Bid Amount</label>
          <vue-numeric
              v-model="quote.amount"
              v-validate="'required'"
              :class="{'is-invalid': errors.has('amount') }"
              class="form-control"
              name="amount"
          />
          <div :class="{'invalid-feedback': errors.has('amount')}">
            {{ errors.first('amount') }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="tenure_duration">Tenure Period (days)</label>
          <vue-numeric
              v-model="quote.tenure"
              v-validate="'required'"
              class="form-control"
              name="tenure_duration"
          />
          <div :class="{'invalid-feedback': errors.has('tenure_duration')}">
            {{ errors.first('tenure_duration') }}
          </div>
        </div>
        <div class="form-group col-md-4">
          <div class="form-check">
            <input
                v-model="terms"
                v-validate="'required'"
                :class="{'is-invalid': errors.has('terms') }"
                class="form-check-input"
                name="terms"
                type="checkbox"
                value="1"
            >
            <label
                class="form-check-label"
                for="terms"
            >
              I have read the <a
                href="#"
                @click.prevent="show_terms=true"
            >terms
              and conditions.</a>
            </label>
            <div :class="{'invalid-feedback': errors.has('terms') }">
              {{ errors.first('terms') }}
            </div>
          </div>
        </div>
        <div
            class="form-group col-md-12"
            style="text-align: right;"
        >
          <a
              v-if="step>1"
              class="btn btn-info pull-left"
              href="#"
              @click.prevent="step=1"
          ><i class="fa fa-chevron-left"></i> Back</a>
          <button
              class="btn btn-primary"
              type="submit"
          >
            Request Quote <i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </form>
      <template v-if="step === 2">
        <h4>Success!</h4>
        <p>The quotation of KES {{ $number.format(quote.amount) }} is:</p>
        <table class="table">
          <tbody>
          <tr>
            <td>Bidbond charge</td>
            <td>{{ $number.format(price.bid_bond_charge) }}</td>
          </tr>
          <tr>
            <td>Excise duty</td>
            <td>{{ $number.format(price.excise_duty) }}</td>
          </tr>
          <tr>
            <td>Indemnity charge</td>
            <td>{{ $number.format(price.indemnity_cost) }}</td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td><strong>KES {{ $number.format(price.total) }}</strong></td>
          </tr>
          </tbody>
        </table>
        <div class="col">
          <a
              class="btn btn-outline-primary mr-2"
              href="#"
              @click.prevent="sendMail"
          ><i
              class="fa fa-envelope"
          />
            SEND QUOTE TO MAIL</a>
          OR
          <router-link
              :to="{ name: 'bidbonds.create' }"
              class="btn btn-outline-info ml-2"
          >
            <i class="fa fa-hand-o-up"/> GENERATE BIDBOND
          </router-link>
        </div>
      </template>
      <template v-if="step === 3">
        <h4>Success!</h4>
        <p>Your quote has been generated and sent to {{ quote.email }}</p>
        <router-link
            :to="{ name: 'bidbonds.create' }"
            class="btn btn-primary"
        >
          GENERATE BIDBOND
        </router-link>
      </template>
    </panel>
    <modal
        :show-modal="show_terms"
        @close="show_terms=false"
    >
      <template v-slot:header>
        <h5>Terms and Conditions</h5>
      </template>
      <div>
        <terms/>
      </div>
      <hr>
      <div class="form-group">
        <a
            class="btn btn-primary"
            href="#"
            @click.prevent="show_terms = false; terms=1"
        >Accept Terms and
          Conditions</a>
        <a
            class="btn btn-danger pull-right"
            href="#"
            @click.prevent="show_terms = false"
        >Cancel</a>
      </div>
    </modal>
  </div>
</template>

<script>
import Terms from "@/components/guest/Terms.vue";

export default {
  components: {
    Terms
  },

  data() {
    return {
      quote: {
        tenure: '',
        amount: '',
        counterparty: '',
        email: '',
        phone: '',
        country: 'kenya'
      },
      terms: '',
      step: 1,
      price: {},
      show_terms: false,
      error: ''
    }
  },
  computed: {
    counter_parties() {
      return this.$store.getters.getCounterParties;
    },
  },

  mounted() {
    this.fetchCounterParties();
  },

  methods: {
    fetchCounterParties() {
      if (!this.counter_parties.length) {
        this.$store.dispatch("fetchCounterParties").then(() => {
          this.loading = false;
        });
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.request();
        }
      });
    },
    request() {
      this.quote.email = this.$store.getters.getAuthUser.email;
      this.quote.phone = this.$store.getters.getAuthUser.phone_number;
      this.$axios.post('/api/v1/quote', this.quote).then(response => {
        this.price = response.data;
        this.step = 2;
        this.error = '';
      }).catch(error => {
        this.error = error.response;
      });
    },

    sendMail() {
      this.$axios.post('/api/v1/send-quote', this.quote).then(response => {
        this.price = response.data;
        this.step = 3;
      }).catch(error => {
        this.error = error.response;
      });
    }
  },
}
</script>
